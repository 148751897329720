// Site Vars
export const siteName = "MASTER YOUR MORTGAGE"
export const backendLocation = process.env.REACT_APP_BACK_END
export const tokenCookieName = "master-your-mortgage-user-token"

// Social Links
export const twitter = "https://twitter.com/"
export const linkedin = "https://linkedin.com/"
export const telephone = "+02081469888"
export const email = "info@masteryourmortgage.com"
export const adminEmail = "admin@masteryourmortgage.com"
export const contactEmail = "contact@masteryourmortgage.com"
export const otherEmail = "other@masteryourmortgage.com"
export const address = "30 Churchill Place, Canary Wharf London, E14 5EU"
export const addressShort = "30 Churchill Place"
export const addressLink = "https://goo.gl/maps/oxvCdYuJS42ywap98"

// Footer
export const footerMarque = [
  {
    "href": "https://www.open-money.co.uk/#",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995c16c33574de5e5f9f_bbc-2.svg"
  },
  {
    "href": "https://www.itv.com/news/granada/2021-01-13/coronavirus-lockdown-tips-looking-after-your-finances",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/60789e9afd6dbd49a44ff6a6_1600px-ITV_(Fernsehsender)_logo.svg.png"
  },
  {
    "href": "https://www.dailymail.co.uk/money/cardsloans/article-9214357/Buy-pay-later-regulations-mean-consumers.html?ns_mchannel=rss&ns_campaign=1490&ito=1490",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995dfa1bca9016dae3a0_daily-mail.svg"
  },
  {
    "href": "https://www.glamourmagazine.co.uk/article/what-is-passive-income",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995c8fcc2f0e3094cf64_glamour.svg"
  },
  {
    "href": "https://www.telegraph.co.uk/investing/funds/brokers-pressure-half-funds-best-buy-lists-failed-2020/",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/60789e9a748b854ab86f24a4_The_Telegraph_logo.svg"
  },
  {
    "href": "https://amp.theguardian.com/money/2021/mar/07/contactless-is-making-it-easier-to-spend-but-is-that-a-good-thing",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995c880cc87edf15a8b9_the-guardian-new-2018.svg"
  },
  {
    "href": "https://www.thesun.co.uk/fabulous/13717462/tips-save-thousands-household-budget/",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995c293570af91a12886_the-sun-newspaper.svg"
  },
  {
    "href": "https://www.express.co.uk/finance/personalfinance/1419503/income-tax-personal-allowances-rise-isa-pension-debt",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/60789d3fd5e3520652c8449e_daily-express-seeklogo.com.svg"
  },
  {
    "href": "https://www.open-money.co.uk/#",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/6078995c46b89d6fefcdeb5c_bbc-radio.svg"
  },
  {
    "href": "https://www.thetimes.co.uk/article/warnings-over-tiktok-influencers-mlprlpxp5",
    "src": "https://assets-global.website-files.com/5fdceff961b534c02157013f/60789e9ac79f138b92b65359_The_Times_logo.png"
  }
]